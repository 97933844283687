import React from 'react';

import './index.scss';
import { formatCurrency } from 'shared/utils';
import { Quote } from 'shared/models';

export interface ACQuoteProps {
  quote: Quote | null;
}

export const ACQuoteDesktop = ({ quote }: ACQuoteProps) => {
  return (
    <div className="taa-quote-desktop-container shadow-md z-50">
      <div className="mb-6 text-lg font-medium">Estimated Premium</div>

      <div className="mt-3 mb-4 grid grid-cols-2">
        <div className="col-span-1">Premium</div>
        <div className="col-span-1 text-right">{formatCurrency(quote?.grossPremium)}</div>
      </div>

      {!!quote?.policyFee && (
        <div className="mt-3 mb-4 grid grid-cols-2">
          <div className="col-span-1">Policy fee</div>
          <div className="col-span-1 text-right">{formatCurrency(quote?.policyFee)}</div>
        </div>
      )}

      {!!quote?.auditCoverFee && (
        <div className="mt-3 mb-4 grid grid-cols-2">
          <div className="col-span-1">AuditCover fee</div>
          <div className="col-span-1 text-right">{formatCurrency(quote?.auditCoverFee)}</div>
        </div>
      )}

      {!!quote?.brokerFee && (
        <div className="mt-3 mb-4 grid grid-cols-2">
          <div className="col-span-1">Broker fee</div>
          <div className="col-span-1 text-right">{formatCurrency(quote.brokerFee)}</div>
        </div>
      )}

      {!!quote?.referrerFee && (
        <div className="mt-3 mb-4 grid grid-cols-2">
          <div className="col-span-1">Referrer Fee</div>
          <div className="col-span-1 text-right">{formatCurrency(quote.referrerFee)}</div>
        </div>
      )}

      <div className="mt-3 mb-4 grid grid-cols-2">
        <div className="col-span-1">GST</div>
        <div className="col-span-1 text-right">{formatCurrency(quote?.gst)}</div>
      </div>

      {!!quote?.stampDuty && (
        <div className="mt-3 mb-6 grid grid-cols-2">
          <div className="col-span-1">Stamp duty</div>
          <div className="col-span-1 text-right">{formatCurrency(quote.stampDuty)}</div>
        </div>
      )}

      <div className="mb-6 grid grid-cols-1">
        <div className="col-span-1">
          <hr className="border-gray-300 opacity-25" />
        </div>
      </div>

      <div className="grid grid-cols-2 font-bold">
        <div className="col-span-1">Total</div>
        <div className="col-span-1 text-right">{formatCurrency(quote?.total)}</div>
      </div>
    </div>
  );
};
