import { useQuery } from '@tanstack/react-query';

import { staleTime } from './config/config';
import { useAxios } from 'shared/hooks';
import { PartnerSearchType } from 'shared/models/partner.type';

export const useGetPartner = (keyword?: string) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['partner-search', keyword],
    queryFn: async () => {
      const params = {
        keyword: keyword,
        pagesize: 10,
      };

      const data = await get<PartnerSearchType>('/api/v2/Partners', { params });

      return data.data;
    },
    enabled: !!keyword,
    staleTime,
  });
};
