import type { FC } from 'react';
import React from 'react';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ACRadio } from 'shared/components/common/Radio/Radio';

interface ACLOCGroupBtnProps {
  options: string[];
  label?: string;
  selectedOption: string;
  onButtonClick: (option: string) => void;
  className?: string;
  boldOption?: boolean;
  isShowTooltipIcon?: boolean;
  openModal?: () => void;
}

export const ACLimitofCoverBtnGroup: FC<ACLOCGroupBtnProps> = ({
  options,
  label,
  selectedOption,
  onButtonClick,
  className,
  openModal,
  boldOption = false,
  isShowTooltipIcon = true,
}) => {
  return (
    <div className={className}>
      <div className="mb-2 font-semibold">
        {label}
        {isShowTooltipIcon && (
          <span onClick={openModal}>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              style={{ color: '#382EBE' }}
              className="ml-1.5 hover:cursor-pointer"
              size="sm"
            />
          </span>
        )}
      </div>

      {options.map((option, index) => (
        <div
          className={`border rounded px-5 py-0.5 bg-[#F7F9FC] flex items-center justify-between mb-3 max-w-[405px] hover:cursor-pointer ${
            option === selectedOption && 'border-blue-700'
          }`}
          key={option}
          onClick={() => {
            onButtonClick(option);
          }}
        >
          <div className="flex items-center">
            <ACRadio checked={selectedOption === option} />

            <span className={`${boldOption ? 'font-semibold' : 'font-medium'} text-[15px]`}>
              {option}
            </span>
          </div>

          {index === 1 && (
            <div className="font-bold text-white bg-[#382EBE] rounded-full text-[11.5px] px-2.5 py-0 leading-5">
              MOST POPULAR
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
