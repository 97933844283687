import React, { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetTaxAuditApp } from 'shared/hooks/api/useGetTaxAuditApp';

interface StageValidatorGuardProps {
  children: React.ReactNode;
}

const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;

const StateValidatorGuard = ({ children }: StageValidatorGuardProps) => {
  const location = useLocation();
  const id = location.pathname.match(uuidPattern)?.join('');
  const navigate = useNavigate();
  const { data: TaaData, isRefetching } = useGetTaxAuditApp(id);

  const isMissingAdditionalType = TaaData?.policyData.additionalEntities?.some(
    entity => !entity.type
  );
  const isHasPrimaryState = TaaData?.policyData?.primaryEntity?.state;

  useLayoutEffect(() => {
    if (!isHasPrimaryState && TaaData?.entityGroup && isMissingAdditionalType) {
      if (TaaData.entityGroup === 'BusinessGroup') {
        navigate(`/business-entity/${id}`);
      } else {
        navigate(`/primary/${TaaData.entityGroup}/${id}`);
      }
      return;
    }

    if (!isRefetching && id) {
      if (isMissingAdditionalType && TaaData?.entityGroup === 'BusinessGroup') {
        navigate(`/business-entity/${id}`);
      } else if (isMissingAdditionalType && TaaData?.entityGroup === 'TrustGroup') {
        navigate(`/additional-businesses-details/${id}`);
      } else if (isMissingAdditionalType && TaaData?.entityGroup === 'SMSFGroup') {
        navigate(`/additional-trustee-details/${id}`);
      } else if (isMissingAdditionalType && TaaData?.entityGroup === 'IndividualGroup') {
        navigate(`/additional-individuals-details/${id}`);
      }
    }
  }, [TaaData, isRefetching, navigate, id]);

  return <>{children}</>;
};

export default StateValidatorGuard;
