import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

import { formatCurrency } from 'shared/utils';
import { ACQuoteProps } from 'shared/components/taa/Quote/Quote-desktop';
import PoweredIcon from 'shared/assets/icon/power-by-ac.svg';
import { ACIcon } from 'shared/components';

export const ACQuoteMobile = ({ quote }: ACQuoteProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        className={`sticky z-50 top-0 border bg-[#100a29] w-full lg:w-auto ${
          expanded ? 'shadow' : ''
        }`}
      >
        <div
          className={`flex justify-between items-center px-4 py-5 cursor-pointer text-[#fff]`}
          onClick={() => setExpanded(!expanded)}
          tabIndex={0}
        >
          <div className="flex space-x-2">
            <span>{formatCurrency(quote?.total)}</span>
            <span>Estimated Premium</span>
          </div>

          <FontAwesomeIcon
            icon={faChevronDown}
            className={`w-5 h-5 transition-transform ${expanded ? 'transform rotate-180' : ''}`}
          />
        </div>

        {expanded && (
          <CSSTransition in={expanded} timeout={300} classNames="accordion" unmountOnExit>
            <div className="px-4 pb-6 space-y-2 z-50 text-[#fff]">
              <div className="flex justify-between">
                <div>Premium</div>
                <div className="text-right">{formatCurrency(quote?.grossPremium)}</div>
              </div>

              {!!quote?.policyFee && (
                <div className="flex justify-between">
                  <div>Policy Fee</div>
                  <div className="text-right">{formatCurrency(quote?.policyFee)}</div>
                </div>
              )}

              {!!quote?.auditCoverFee && (
                <div className="flex justify-between">
                  <div>AuditCover Fee</div>
                  <div className="text-right">{formatCurrency(quote?.auditCoverFee)}</div>
                </div>
              )}

              {!!quote?.brokerFee && (
                <div className="flex justify-between">
                  <div>Broker fee</div>
                  <div className="text-right">{formatCurrency(quote.brokerFee)}</div>
                </div>
              )}

              {!!quote?.referrerFee && (
                <div className="flex justify-between">
                  <div>Referrer Fee</div>
                  <div className="text-right">{formatCurrency(quote?.referrerFee || 0)}</div>
                </div>
              )}

              <div className="flex justify-between">
                <div>GST</div>
                <div className="text-right">{formatCurrency(quote?.gst)}</div>
              </div>

              {!!quote?.stampDuty && (
                <div className="flex justify-between">
                  <div>Stamp Duty</div>
                  <div className="text-right">{formatCurrency(quote.stampDuty)}</div>
                </div>
              )}
            </div>
          </CSSTransition>
        )}
      </div>

      <div className="flex justify-center mt-2">
        <ACIcon imgSrc={PoweredIcon} />
      </div>
    </>
  );
};

export default ACQuoteMobile;
