import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quote, QuoteRequestType } from 'shared/models';

interface ACSliceProps {
  quote: Quote | null;
  apiError: string | null;
  isEQDialogOpen: boolean;
  isSuccessDialogOpen: boolean;
  isShowReentry: boolean;
  summaryCalculationParams: Partial<QuoteRequestType> | null;
}

const initialState: ACSliceProps = {
  quote: null,
  apiError: null,
  isEQDialogOpen: false,
  isSuccessDialogOpen: false,
  isShowReentry: true,
  summaryCalculationParams: null,
};

const TaaSlice = createSlice({
  name: 'taa',
  initialState,
  reducers: {
    updateQuote: (state, action: PayloadAction<ACSliceProps['quote']>) => {
      state.quote = action.payload;
    },
    updateApiError: (state, action: PayloadAction<ACSliceProps['apiError']>) => {
      state.apiError = action.payload;
    },
    updateEQDialogStatus: (state, action: PayloadAction<ACSliceProps['isEQDialogOpen']>) => {
      state.isEQDialogOpen = action.payload;
    },
    updateSuccessDialogStatus: (
      state,
      action: PayloadAction<ACSliceProps['isSuccessDialogOpen']>
    ) => {
      state.isSuccessDialogOpen = action.payload;
    },
    updateIsShowReentry: (state, action: PayloadAction<ACSliceProps['isShowReentry']>) => {
      state.isShowReentry = action.payload;
    },
    updateSummaryCalculationParams: (
      state,
      action: PayloadAction<ACSliceProps['summaryCalculationParams']>
    ) => {
      state.summaryCalculationParams = action.payload;
    },
  },
});

export const {
  updateQuote,
  updateApiError,
  updateEQDialogStatus,
  updateSuccessDialogStatus,
  updateIsShowReentry,
  updateSummaryCalculationParams,
} = TaaSlice.actions;

export const TaaReducer = TaaSlice.reducer;
