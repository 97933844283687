import { useQuery } from '@tanstack/react-query';

import { staleTime } from './config/config';
import { useAxios } from 'shared/hooks';
import { IndustrySearchType } from 'shared/models';

export const useGetIndustry = (keyword?: string) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['industry-search', keyword],
    queryFn: async () => {
      const params = {
        keyword: keyword,
        pagesize: 10,
      };

      const data = await get<IndustrySearchType>('/api/v2/industries', { params });

      return data.data;
    },
    enabled: !!keyword,
    staleTime,
  });
};
