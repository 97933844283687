import React from 'react';
import Select, {
  Props as SelectProps,
  StylesConfig,
  components as selectComponents,
  DropdownIndicatorProps,
} from 'react-select';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ACSelectProps extends SelectProps {
  className?: string;
  label?: string;
  isSearch?: boolean;
  isSearchIcon?: boolean;
  errorMessage?: string;
  isClearable?: boolean;
  isSummary?: boolean;
}

const getCustomStyles = (isSummary: boolean): StylesConfig => ({
  control: (styles, { isFocused, menuIsOpen }) => ({
    ...styles,
    backgroundColor: menuIsOpen || isFocused ? '#fff' : '#f7f9fc',
    border: menuIsOpen ? '1px solid #382ebe' : '1px solid #D1D5DB',
    borderRadius: '0.375rem',
    minHeight: isSummary ? '2.8rem' : '3rem',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#D1D5DB',
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#36f' : isFocused ? '#382ebe' : undefined,
      color: isSelected ? '#fff' : isFocused ? '#fff' : '#262626',
      cursor: 'pointer',
      minHeight: '2rem',
      display: 'flex',
      alignItems: 'center',
      padding: '0.2rem 1rem',
      fontSize: '0.975rem',
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: 500,
    };
  },
  input: styles => ({
    ...styles,
    fontSize: '0.875rem',
    color: '#333',
    fontFamily: 'Open Sans,sans-serif',
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: isSummary ? '0.88rem' : '0.975rem',
    color: '#94A3B8',
    fontWeight: 500,
    marginLeft: '7px',
  }),
  singleValue: styles => ({
    ...styles,
    fontSize: isSummary ? '0.8rem' : '0.875rem',
    color: '#5F616A',
    fontFamily: 'Open Sans,sans-serif',
    fontWeight: 600,
    marginLeft: '7px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
});

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <selectComponents.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </selectComponents.DropdownIndicator>
  );
};

export const ACSelect = React.forwardRef(
  (
    {
      className,
      defaultValue,
      options,
      label,
      isSearch = false,
      isSearchIcon = false,
      isClearable = false,
      errorMessage,
      isSummary = false,
      ...props
    }: ACSelectProps,
    ref: React.ForwardedRef<any>
  ) => {
    const customStyles = getCustomStyles(isSummary);

    return (
      <div className={className}>
        <div className="mb-2 font-semibold">{label}</div>

        <Select
          ref={ref}
          defaultValue={defaultValue}
          options={options}
          styles={customStyles}
          isSearchable={isSearch}
          isClearable={isClearable}
          components={isSearchIcon ? { DropdownIndicator } : undefined}
          noOptionsMessage={() => null}
          {...props}
        />

        {errorMessage && (
          <div className={`text-[#d02b2b] font-normal ${isSummary ? 'text-sm' : 'text-base'} mt-1`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

ACSelect.displayName = 'ACSelect';
