import { useEffect, useRef } from 'react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';

import config from 'shared/assets/config';
import { updateApiError } from 'shared/store/slices/taa.slice';
import { ApiErrorType } from 'shared/models';

export const useAxios = () => {
  const apiKey = config.apiKey;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const http = useRef<AxiosInstance>(
    axios.create({
      baseURL: config.apiBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        apiKey,
      },
    })
  );

  useEffect(() => {
    const responseInterceptor = http.current.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError<ApiErrorType>) => {
        if (error?.response?.status === 400) {
          const globalErrorMsg = error?.response?.data?.globalErrors
            .map(er => er.message || '')
            .filter(Boolean)
            .join('\n');

          const fieldErrors = error?.response?.data?.fieldErrors || {};
          const fieldErrorMsgs = Object.keys(fieldErrors)
            .map(field => {
              const messages = fieldErrors[field].map(msg =>
                msg === 'The PartnerId field is required.'
                  ? 'Please contact us for assistance with your quote at either 1300 895 797 or hello@auditcover.com.'
                  : msg
              );

              return `${messages.join(', ')}`;
            })
            .join('\n');

          const combinedErrorMsg = [globalErrorMsg, fieldErrorMsgs].filter(Boolean).join('\n');
          dispatch(updateApiError(combinedErrorMsg));
        }
      }
    );

    return () => {
      http.current.interceptors.response.eject(responseInterceptor);
    };
  }, [executeRecaptcha]);

  const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    return http.current.get<T>(url, config).then((response: AxiosResponse<T>) => response.data);
  };

  const post = async <T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
    const finalConfig = {
      ...config,
      headers: {
        ...config?.headers,
        'x-grecaptcha': 'E7B81544-E48A-449F-A3C5-8EDB980C04A6',
      },
    };

    return http.current
      .post<T, AxiosResponse<R>>(url, data, finalConfig)
      .then((response: AxiosResponse<R>) => response.data);
  };

  const put = async <T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
    const finalConfig = {
      ...config,
      headers: {
        ...config?.headers,
        'x-grecaptcha': 'E7B81544-E48A-449F-A3C5-8EDB980C04A6',
      },
    };

    return http.current
      .put<T, AxiosResponse<R>>(url, data, finalConfig)
      .then((response: AxiosResponse<R>) => response.data);
  };

  const patch = async <T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
    const finalConfig = {
      ...config,
      headers: {
        ...config?.headers,
        'x-grecaptcha': 'E7B81544-E48A-449F-A3C5-8EDB980C04A6',
      },
    };

    return http.current
      .patch<T, AxiosResponse<R>>(url, data, finalConfig)
      .then((response: AxiosResponse<R>) => response.data);
  };

  return { get, post, put, patch };
};
