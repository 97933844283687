import React from 'react';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface ACAccordionProps {
  children: React.ReactNode;
  header: string;
}

export const ACAccordion = ({ children, header }: ACAccordionProps) => {
  return (
    <div className="mx-auto w-full rounded bg-[#f7f9fc] text-[15px] text-black ">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex w-full justify-between items-center rounded-t bg-[#f7f9fc] p-5 text-left text-[15px] font-semibold text-black 
                ${
                  open ? 'border-t border-l border-r border-[#e4e9f2]' : 'border border-[#e4e9f2]'
                }`}
            >
              <span>{header}</span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`${open ? 'rotate-180 transform' : ''} h-3 w-3 text-black`}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="border-l border-r border-b border-[#e4e9f2] rounded-b px-5 pt-3 pb-8 font-medium">
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
